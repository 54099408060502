/* istanbul ignore file */
import { getTopBannerContent } from '@noths/polaris-client-banners';
import type { FeatureFlags } from '@noths/polaris-client-feature-flags';
import { Environment, fetchNavigationContent } from '@noths/polaris-client-site-chrome-ui';
import * as runtimeProcess from 'process';

import { logger } from 'src/utils/serverLogger';

const { ENV_TYPE } = runtimeProcess.env;

const isProd = ENV_TYPE && ['production', 'staging', 'oldstaging'].includes(ENV_TYPE);

interface GlobalContentOptions {
  featureFlags: FeatureFlags;
  urlPath?: string;
}

export const fetchGlobalContent = async ({ featureFlags, urlPath }: GlobalContentOptions) => {
  const env = isProd ? Environment.Production : Environment.Qa;

  const config = { env, logger };
  const navContentVariantId = featureFlags.nav_content_experiment;

  return Promise.all([
    fetchNavigationContent(config, navContentVariantId).catch(() => null),
    getTopBannerContent(config, { featureFlags, urlPath }),
  ]);
};
